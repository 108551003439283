import React, { useState } from 'react';
import Switch from 'react-switch';
import './QuestionSwitch.css';
import colors from '../../Colors.js';


const QuestionSwitch = (props) => {
    const [checked, setChecked] = useState(false);

    return(
        <div className="question_switch">
            <h3 style={{color: colors.black}}>{props.label}</h3>
            <div className="switch_wrapper">
                <div className="switch">
                    <Switch onColor={colors.yellow} onHandleColor="#000" offHandleColor={colors.yellow} checkedIcon={false} uncheckedIcon={false} offColor="#000" onChange={() => setChecked(checked)} checked={checked} />
                </div>
            </div>
        </div>
    );
}

export default QuestionSwitch;