import React from "react";
import Database from '../../classes/Database.js';
import SummaryIcon from "../summaryicon/SummaryIcon.js";
import TimelineEntry from "../timelineentry/TimelineEntry.js";
import EntryHelpers from '../../classes/EntryHelpers.js';
import EntryModal from '../entrymodal/EntryModal.js';
import DateHelpers from '../../classes/DateHelpers.js';
import TargetModal from '../targetmodal/TargetModal.js';
import StepTimeline from '../steptimeline/StepTimeline.js';
import colors from '../../Colors.js';
import './TimelineWeek.css';

const TimelineWeek = ({number, entries, gambling, week}) => {
    let view = gambling ? <ValueTimeline number={number} week={week} entries={entries} /> : <StepTimeline week={week} entries={entries} />;
    //console.log("Gambling is "+gambling);
    return view;
}

class ValueTimeline extends React.Component {
    constructor(){
        super();
        this.state = {
            targets: {},
            spendTotal: 0,
            timeTotal: 0,
            modal: false,
            targetModal: false,
        }
    }

    componentDidMount(){
        this.updateTotals(this.props.week);
        this.getTargets(this.props.week);
    }

    async getTargets(week){
        let db = new Database();
        let targets = db.returnTargets(week);
        this.setState({
            targets: await targets
        })
    }

    returnTimelineEntries(){
        let jsx = [];
        let entryKey = "week"+this.props.week;
        let obj = this.props.entries[entryKey];
        if(obj === undefined) return;
        let formArray = EntryHelpers.sortArray(obj);
        var days = Object.keys(formArray);
        let weekStart = DateHelpers.dateToTimestamp(days[6]);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov","Dec"];
        this.weekDate = weekStart.getDate()+" "+months[weekStart.getMonth()]+" "+weekStart.getFullYear();

        for(var i = 0; i < 7; i++){
            jsx.push(<TimelineEntry steps={false} targets={this.state.targets} key={"te"+i} week={this.props.week} updateTotals={this.updateTotals.bind(this)} date={days[i]} values={obj[days[i]]} />);
        }

        return jsx;
    }

    toggleTargetModal(){
        this.setState({targetModal: !this.state.targetModal})
    }

    toggleEntryModal(){
        this.setState({entryModal: !this.state.entryModal})
    }

    async updateTotals(week){
        let db = new Database();
        let date = await db.getFirstDayOfGivenWeek(week);
        let ts = DateHelpers.dateToTimestamp(date[0]);
        let totals = await db.getWeekTotal(ts);
        
        this.setState({
            spendTotal: totals.spend,
            timeTotal: totals.time
        })
    }

    toggleDrawer(number){
        var tDrawer = document.getElementById(`drawerid${number}`);
        
        if(tDrawer.style.right === '-150px'){
            tDrawer.style.right = '0px';
        } else {
            tDrawer.style.right = '-150px';
        }
    }

    returnSpendTarget(spend){
        if(spend === undefined | spend === null){
            spend = 0;
        } 
        return spend;
    }

    render(){
        let week = this.props.week;

        return(
            <div className="timeline_week_wrapper">
                <h1 style={{ color: colors.black, marginTop: "20px", marginBottom: "10px", marginLeft: "20px"}}>Week of: {this.weekDate}</h1>
                <div className="timeline_summary">
                    <SummaryIcon labelColor={colors.black} icon="money" label={"£"+this.state.spendTotal} />
                    <SummaryIcon labelColor={colors.black} icon="time" label={DateHelpers.returnFormattedTime(this.state.timeTotal)} />
                    <SummaryIcon labelColor={colors.black} icon="goals" label="Targets" onClick={this.toggleTargetModal.bind(this)} />
                    
                    { /* **Timeline Drawer**
                    <div id={"drawerid"+this.props.number} className="target_drawer" style={{right: '-150px'}} onClick={() => this.toggleDrawer(this.props.number)}>
                        <div className="target_image_wrapper">
                            <img src={require('../../assets/icons/target_green.png')} alt="Target" />
                        </div>
                        <div className="target_drawer_container">
                            <div className="target_drawer_row">
                                <div className="target_icon_wrapper">
                                    <img src={require('../../assets/icons/money_white.png')} alt="Money" />
                                    <h3 style={{fontSize: 18}}>£{this.returnSpendTarget(this.state.targets.spend * 7)}</h3>
                                </div>
                            </div>
                            <div className="target_drawer_row">
                                <div className="target_icon_wrapper">
                                    <img src={require('../../assets/icons/time_white.png')} alt="Money" />
                                    <h3 style={{fontSize: 18}}>{DateHelpers.returnFormattedTime(this.state.targets.time * 7)}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    */ }

                

                </div>
                <div style={{position: 'relative', paddingBottom: "20px"}}>
                    <div className="timeline_ladder"></div>
                    {this.returnTimelineEntries()}
                </div>
                {/*<EntryModal week={week} entries={this.props.entries} visible={this.state.entryModal} onClick={this.toggleEntryModal.bind(this)} />*/}
                <TargetModal updateTargets={this.getTargets.bind(this)} week={week} onClick={this.toggleTargetModal.bind(this)} visible={this.state.targetModal} targets={this.state.targets} />
            </div>
        );
    }
}

export default TimelineWeek;