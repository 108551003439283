import React from 'react';
import colors from '../../Colors.js';
import './informationsheet.css';


const Infosheet = (props) => {
    
    let participantInformation = <div className="info_sheet">
        <img src={require("../../assets/logos/UofGlogo.png")} alt="University of Glasgow College of Social Sciences" />
        <h1>
            Participant Information Sheet 
        </h1>
        <p>
            Football Fans and Betting (FFAB): a feasibility study and randomised pilot trial of a group-based intervention to reduce gambling involvement among male football fans
        </p>
        <p style={{fontSize:"0.8em", margin: "20px auto", color: colors.black, width: "90%"}}>
            <strong>Lead Investigator: </strong> 
            Professor Gerda Reith (gerda.reith@glasgow.ac.uk). 
        </p>
        <p style={{fontSize:"0.8em", margin: "20px auto", color: colors.black, width: "90%"}}>
            <strong>Co-Investigators: </strong>
            Dr Heather Wardle (heather.wardle@glasgow.ac.uk); Professor Cindy Gray (cindy.gray@glasgow.ac.uk); Dr Chris Bunn (christopher.bunn@glasgow.ac.uk); Professor Kate Hunt (Kate.hunt@stir.ac.uk); Professor Sally Wyke (sally.wyke@glasgow.ac.uk); Professor Robert Rogers  (r.rogers@bangor.ac.uk); Dr John Rooksby (John.rooksby@northumbria.ac.uk); Ms Nicola Greenlaw (nicola.greenlaw@glasgow.ac.uk); Dr Matthew Philpott (Matthew.philpott@healthystadia.eu); Dr Manuela Deidda (Manuela.deidda@glasgow.ac.uk); Mr Neil Platt (Neil.platt@beaconcounsellingtrust.co.uk); Mr Frankie Graham (frankie@betknowmoreuk.org) 
        </p>
        <p style={{fontSize:"0.8em", margin: "20px auto", color: colors.black, width: "90%"}}>
            <strong>Study Coordinator: </strong>
            Dr Craig Donnachie 
        </p>
        <p>
            You are being invited to take part in a research study. Before you decide it is important for you to understand why the research is being done and what it will involve. Please take time to read the following information carefully and discuss it with others if you wish. Ask us if there is anything that is not clear or if you would like more information. Take time to decide whether or not you wish to take part.
            Thank you for reading this. 
        </p>
        <p>
            The research that we are asking you to take part in is the first part of a larger, two-year study. The overall study aims to develop a programme, called FFAB (Football Fans and Betting) that will help male sports fans to reduce the amount of time and money that they spend on betting. 
        </p>
        <p>
            Our goal in this first phase is to carry out exploratory work to help us to better understand the attitudes of our target audience, specifically to develop the materials for the next stages of the research. We would like to invite you to help us test out a bespoke, purpose-built smartphone app. 
        </p>
        <p>
            If you decide to take part, you will be invited to download the app and use it to record the amount of time and money spent gambling for at least one full day, as well as your daily physical activity levels. After this time, one of the researchers will contact you over the phone to ask you about your experience of using the app. The conversation will last no longer than 30 minutes. 
        </p>
        <p>
            The conversation will be audio recorded using a secure device. The recording will then be transcribed, and the transcript and the notes of interview will be identified with a unique ID number. The names corresponding with those numbers will be kept separately on a password protected list (destroyed on completion of the project). Data will be uploaded and held in a managed storage environment on a university server, accessible only by named members of the research team. No real names will be used in reports/publications and identifying details will be removed. Participation is entirely voluntary, and you can decide to stop the conversation or use of the app at any time. You will be offered a ‘thank you’ high street gift voucher worth £20 for taking part.
        </p>
        <p>
            Confidentiality will be respected unless there are compelling and legitimate reasons for this to be breached. If this was the case, we would inform you of any decisions that might limit your confidentiality.
        </p>
        <p>
            If you decide to download the app, you’ll be asked to enter your email address and a password to ensure the app-data entered is secure. Access to data collected via the app will be available by password only and stored securely on university servers. Participant data entered via the app will be collected and stored on a cloud server called ‘Firebase’ which is a GDPR compliant platform developed by Google for creating mobile and web applications. At the end of the project, data from the app, including personal data like usernames will be securely deleted. 
        </p>
        <p>
            Once the study is over, we might share your pseudonymised research data with other researchers, in line with good research practice. They will never know your name. No real names will be used in any reports or publications, and all identifying details will be removed. Your research data will be retained for a period of ten years. After this time, it will be destroyed in line with the University of Glasgow Guidelines.
        </p>
        <p>
            The findings will be used to refine the app for the FFAB programme itself and make it more effective. They will also help us better understand issues around sport and betting, which in turn will help us to prepare for the next stage of the research. 
        </p>
        <p>
            The study is being funded by the National Institute of Health Research (NIHR 127665). NIHR is the nation’s largest funder of health and care research. it is primarily funded by the Department of Health and Social Care and works in partnership with the NHS, universities, local government, other research funders, patients and the public to improve the health and wellbeing of the nation. https://www.nihr.ac.uk/ 
        </p>
        <p>
            This project has been considered and approved by the University of Glasgow’s College Research Ethics Committee
        </p>
    </div>;


    let furtherInformation = <div>
        <h1>
            Further sources of information, advice and support
        </h1>
        <p>
            <strong>
                Beacon Counselling Trust: 
            </strong> 
            provides services for those affected by gambling, as well as mental health issues. This service provides one-to-one, couples or group therapy sessions for those affected by gambling, as well as their friends and families. It also offers advice and guidance on a range of other issues including debt management, mental health and social support.
        </p>
        <ul>
            <li><strong>T:</strong> 0151 321 1099</li>
            <li><strong>E:</strong> admin@beaconcounsellingtrust.co.uk</li>
            <li><a href="http://beaconcounsellingtrust.co.uk/">http://beaconcounsellingtrust.co.uk/</a></li>
        </ul>

        <p>
            <strong>Betknowmore: </strong> 
            a gambling support service that provides peer-led support for people affected by gambling harms.
        </p>
        <ul>
            <li><strong>T:</strong> 0800 066 4827 | Mon-Fri 9am-9pm</li>
            <li><strong>E:</strong> info@betknowmoreuk.org</li>
            <li><a href="https://www.betknowmoreuk.org/">https://www.betknowmoreuk.org/</a>  </li>
        </ul>

        <p>
            <strong>GamCare: </strong>  

            a national treatment provider who provide expert information, advice and support to anyone affected by gambling. They offer a range of services, including a 24 hour helpline phone number, online counselling, chatrooms and message boards
        </p>
        <ul>
            <li><strong>T:</strong> 0808 802 0133 | 8am to Midnight</li>
            <li><a href="https://www.gamcare.org.uk/">https://www.gamcare.org.uk/</a> </li>
        </ul>

        <p>
            <strong>Debt Advice Agency: </strong> 
            a national agency offering advice on financial problems 
        </p>
        <ul>
            <li>T: 01209 310090</li>
            <li>E: info@thedebtadviceagency.com </li>
            <li><a href="http://www.thedebtadviceagency.com/">http://www.thedebtadviceagency.com/</a></li>
        </ul>

        <p>
            If you require further information or wish to pursue any complaint you should contact the College of Social Sciences Lead for Ethical Review, Dr Susan Batchelor, email: socsci-ethics-lead@glasgow.ac.uk
        </p>
    </div>;


    let consentForm = <div>
    <h2>Consent</h2>
    <p>
        I confirm that I have read and understood the Participant Information Sheet for the above study and have had the opportunity to ask questions.
    </p>
    <p>
        I understand that my participation is voluntary and that I am free to withdraw at any time, without giving any reason.
    </p>
    <p>
        I acknowledge that participants will be referred to by pseudonym.
    </p>
    <p>
        I acknowledge that there will be no effect on my employment arising from my participation or non-participation in this research.
    </p>
    <p>
        I understand that:
    </p>
    <ul>	
        <li>All names and other material likely to identify individuals will be anonymised.</li>
        <li>The material will be treated as confidential and securely stored on university servers.</li>
        <li>Data entered via the app will be collected and stored on a cloud server developed by Google called ‘Firebase’ which is a GDPR compliant platform.</li>
        <li>Personal data will be retained only for as long as is necessary and no longer than the period of ethical approval (until 31/12/2023).</li>
        <li>All other research data will be retained for 10 years post-project. After this time, it will be destroyed in line with University of Glasgow Guidelines.</li>
        <li>Once the study is over, we might share your pseudonymised research data with other researchers, in line with good research practice. No real names will be used, and all identifying details will be removed.</li>
        <li>The material may be used in future publications, both print and online.</li>
        <li>I agree to waive my copyright to any data collected as part of this project.</li>
        <li>I agree to download and test the FFAB smartphone app for at least one full day.</li> 
        <li>I agree to being contacted by telephone to take part in a conversation about my experiences of using the app.</li>
    </ul>

    </div>;

    const infoPages = [
        participantInformation,
        furtherInformation,
        consentForm
    ]

    return(
        <div> 
            <section className='participant_information'>
                {infoPages[props.consentStage]}
            </section>
        </div>
    );
}
export default Infosheet;