/**
 * Plus Button
 * 
 * This will display a 'cancel' button for adding data. The button is a rotated x.
 * This component is similar to plusbutton.
 * 
 */

 import React from 'react';
 import './CancelButton.css';
 
 const CancelButton = (props) => {
      
     return(
         <div className="cancelbutton_wrapper"  onClick={() => props.onClick()}>
             <h3 style={{transform: "rotate(45deg)"}} className="plus_value">+</h3>
         </div>
     );
 }
 
 
 export default CancelButton;