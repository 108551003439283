import React, {useEffect} from "react";
import Authentication from "../../../classes/Authentication";
import { Link, useHistory } from 'react-router-dom';


const Signout = () => {
    let history = useHistory();

    const performSignOut = async () => {
        let auth = new Authentication();
        let signedOut = auth.signOut();

        if(signedOut){
            history.push("/login");
        }
    }

    useEffect(() => {
        performSignOut();
    }, [])

    return (
        <div>
        <p>Signing out</p>
        
        </div>
    );
}

export default Signout;