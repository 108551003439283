import React from 'react';

const Notification = () => {
    return(
        <div>
            <h1>test</h1>
        </div>
    );
}

export default Notification;