import React, { useEffect, useState } from 'react';
import Header from '../../header/Header.js';
import {Link} from 'react-router-dom';
import Background from '../../../assets/backgrounds/pexels-fabricio-trujillo-54567.jpg';
import {useHistory} from 'react-router-dom'; 
import Authentication from '../../../classes/Authentication.js';
import colors from '../../../Colors.js';
import './Settings.css';


const Settings = () => {
    let history = useHistory();
    const [authStatus, setAuthStatus] = useState(false);


    useEffect(() => {
        let auth = new Authentication();
        let isMounted = true;

        const updateAuthStatus = (status) => {
            if (isMounted) {
                setAuthStatus(status);
            }
        }
        auth.checkAuthenticated(history, updateAuthStatus);

        return () => { isMounted = false };
    }, [history])

    return(
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`}}>
            {(authStatus === true) && 
            <div className="content_wrapper">
                <Header />
                <div style={{width: "100%", height: "100%", marginTop: '0px'}}>
                    <div className="settings_list" style={{color: colors.black}}>
                        <ul>
                            {/*<Link to="/settings/updatedetails">
                                <li style={{color: colors.black, borderBottom: "solid 0px black"}}>Update Details</li>
                            </Link>*/}
                            <Link to="/settings/resetpassword">
                                <li style={{color: colors.black, borderBottom: "solid 0px black"}}>Reset Password</li>
                            </Link>
                            <Link to="/signout">
                                <li style={{color: 'red'}}>Sign Out</li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default Settings;