import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <div className="footer">
            <h2>About</h2>
            <p>This application has been produced as part of a research project. For general information about the research please see <a href="http://reclaimthegame.co.uk">reclaim the game</a></p>  
            <h2>Privacy</h2>
            <p>Northumbria University provides a <a href="https://www.northumbria.ac.uk/about-us/leadership-governance/vice-chancellors-office/legal-services-team/gdpr/gdpr---privacy-notices/">research participant privacy notice</a></p>
            <h2>Contact</h2>      
            <p>Dr John Rooksby, Northumbria University. john.rooksby@northumbria.ac.uk</p>
        </div>
    )
}

export default Footer;