import React, { Component } from 'react';
import colors from '../../Colors.js';
import SummaryIcon from '../summaryicon/SummaryIcon.js';
import TimelineEntry from '../timelineentry/TimelineEntry.js';
import Database from '../../classes/Database.js';
import DateHelpers from '../../classes/DateHelpers.js';
import EntryHelpers from '../../classes/EntryHelpers.js';
import TargetModalSteps from '../targetmodalsteps/TargetModalSteps.js';

export default class StepTimeline extends Component {
    constructor(){
        super();
        this.state = {
            targets: {},
            entries: {},
            stepTotal: 0,
            entryModal: false,
            targetModal :false,
            drawerRight: -150,
            selectedDate: null
        }
    }

    componentDidMount(){
        this.updateEntries(this.props.entries);
        this.updateTotals(this.props.week);
        this.getTargets(this.props.week);
    }

    updateEntries(entries){
        this.setState({ entries: entries })
    }

    componentDidUpdate(prevProps){
        if(prevProps.entries !== this.props.entries){
            this.updateEntries(this.props.entries);
            this.updateTotals(this.props.week);
        }
    }

    async getTargets(week){
        let db = new Database();
        let targets = db.returnTargets(week);
        this.setState({ targets: await targets })
    }

    returnTimelineEntries(entries){
        let jsx = [];
        let entryKey = "week"+this.props.week;
        let obj = entries[entryKey];
        if (obj === undefined | obj === null) return;
        let formArray = EntryHelpers.sortArray(obj);
        let days = Object.keys(formArray);
        let weekStart = DateHelpers.dateToTimestamp(days[6]);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        this.weekDate = `${weekStart.getDate()} ${months[weekStart.getMonth()]} ${weekStart.getFullYear()}`;

        for(var i = 0; i < 7; i++){
            if(days[i] !== "targets"){
                jsx.push(<TimelineEntry key={((Math.random() * 6) * i)} steps={true} id={this.props.week+":"+i} toggleEntryModal={this.toggleEntryModal.bind(this)} targets={this.state.targets} week={this.props.week} updateTotals={this.updateTotals.bind(this)} date={days[i]} values={obj[days[i]]} />);
            }
        }
        return jsx;
    }

    async updateTotals(week){
        let db = new Database();
        let date = await db.getFirstDayOfGivenWeek(week);
        let ts = DateHelpers.dateToTimestamp(date[6]);
        let total = await db.getWeekStepTotal(ts);
        this.setState({stepTotal: total});
    }

    toggleTargetDrawer(){
        if(this.state.drawerRight === -150){
            this.setState({ drawerRight: 0 });
        } else {
            this.setState({ drawerRight: -150 });
        }
    }

    toggleEntryModal(date){
        if(this.state.entryModal === false){
            this.setState({ entryModal: true, selectedDate: date })
        } else {
            this.setState({ entryModal: false, selectedDate: null })
        }
    }

    setSelectedDate(selectedDate){
        this.setState({ selectedDate: selectedDate })
    }

    toggleTargetModal(){
        this.setState({ targetModal: !this.state.targetModal })
    }

    returnTargets(steps){
        if(steps === undefined | steps === null)
            steps = 0

        return steps
    }

    render(){
        return(
            <div className="timeline_week_wrapper">
                <h1 style={{color: colors.black, marginTop: "20px", marginBottom: "10px", marginLeft: "20px"}}>{this.weekDate}</h1>
                <div className="timeline_summary">
                    <SummaryIcon labelColor={colors.black} icon="steps" label={`${this.state.stepTotal}`} />
                    <SummaryIcon labelColor={colors.black} icon="goals" label="Targets" onClick={this.toggleTargetModal.bind(this)} />
                    {/*
                    <div className="target_drawer" style={{right: this.state.drawerRight}} onClick={() => this.toggleTargetDrawer()}>
                        <div className="target_image_wrapper">
                            <img src={require('../../assets/icons/target_green.png')} alt="Target" />
                        </div>
                        
                        <div className="target_drawer_container">
                            <div className="target_drawer_row">
                                <div className="target_icon_wrapper">
                                    <img src={require('../../assets/icons/shoe_white.png')} alt="Shoe" />
                                    <h3>{this.returnTargets(this.state.targets.steps) * 7}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                </div>
                <div style={{position: 'relative', paddingBottom: "20px"}}>
                    <div className="timeline_ladder"></div>
                    {this.returnTimelineEntries(this.state.entries)}
                </div>
                <TargetModalSteps updateTargets={this.getTargets.bind(this)} week={this.props.week} onClick={this.toggleTargetModal.bind(this)} visible={this.state.targetModal} />
            </div>
        );
    }
}