import React from 'react';
import { useHistory} from 'react-router-dom';
import './Minheader.css';
import colors from '../../Colors.js';


const Minheader = (props) => {
    let history = useHistory();

    return(
        <div className="min_header">
            <div className="back_button_container" onClick={() => history.goBack()}>
                <h1 style={{color: colors.black}}>&#10148;</h1>
            </div>
            <div className="header_container">
                <h1 style={{color: colors.black}}>{props.heading}</h1>
            </div>
        </div>
    );
}

export default Minheader;