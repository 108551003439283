import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Login from './components/views/login/Login.js';
import SignUp from './components/views/signup/Signup.js';
import Home from './components/views/home/Home.js';
import About from './components/views/about/About.js';
import ForgotPassword from './components/views/forgotpassword/ForgotPassword.js';
import Settings from './components/views/settings/Settings.js';
import Set from './components/views/set/Set.js';
import Authentication from './classes/Authentication.js';
import Signout from './components/views/signout/Signout.js';
import Error from  './components/views/error/Error.js';
import './App.css';
import './MediaQueries.css';

export default class App extends Component {
  componentDidMount(){
    // initialise firebase
    new Authentication();
  }

  render(){
    return(
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/signout" component={Signout} />
          <Route path="/signup" component={SignUp} />
          <Route path="/home" component={Home} />
          <Route path="/steps" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/error" component={Error} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/settings" component={Settings} />
          <Route path="/settings/:tab" component={Set} />
        </Switch>
      </Router>
    );
  }
}
