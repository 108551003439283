import React, { Component } from 'react';
import colors from '../../Colors.js';
import DateHelpers from '../../classes/DateHelpers.js';
import './StepBubble.css';

class StepBubble extends Component {
    constructor(props){
        super();
        this.state = {
            values: 0,
            target: {}
        }
    }

    componentDidMount(){
        this.setState({ 
            values: this.props.values,
            target: this.props.targets
        })
    }

    componentDidUpdate(prevProps){
        if(this.props.values !== prevProps.values){
            this.setState({values: this.props.values})
        }
    }

    formatSteps(steps){
        if(steps === undefined){
            return 0;
        } else {
            return steps;
        }
    }

    render(){
        let date = this.props.date; 
        let dow = DateHelpers.dateToTimestamp(date);
        let isPast = DateHelpers.verifyPastDate(dow);
        let display = isPast ? null : "none";
    
        return(
            <div>
            <div style={{marginBottom: "5px", color: colors.black, display: display}} className="timeline_row">
                <h4 style={{color: colors.black, flexBasis: "100%", margin: 0,marginLeft: "60px", padding: 0, fontSize: 20}}>{date}</h4>
            </div>
            <div style={{marginBottom: "40px", color: colors.black, display: display}} className="timeline_row">
                <div className="entry_dot" style={{backgroundColor: colors.yellow }}><h1 style={{fontSize: 20, marginLeft: 0}}>{DateHelpers.returnDay(date)}</h1></div>
                <div className={"entry_bubble_shoe_collapsed"} style={{backgroundColor: colors.yellow, position: 'relative'}}>
                    <div className={"empty_target_img_closed"}>
                        <div className="empty_shoe_column">
                            <img src={require('../../assets/icons/shoe_white.png')} alt="Shoe" />
                        </div>
                        <div className="empty_steps_column">
                            <h1 style={{margin: 0, padding: 0, fontSize: 25 }}>{this.formatSteps(this.state.values.steps)}</h1>
                        </div>
                        <TargetIndicator date={this.props.date} targets={this.state.target} values={this.state.values} />
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

const TargetIndicator = ({targets, values}) => {
    var stepTarget = 0;
    if (targets.steps !== undefined)
        stepTarget = targets.steps;

    const styles = {left: "60px", bottom: "-20px", backgroundColor: colors.yellow};

    if(values.steps !== undefined && !isNaN(parseInt(values.steps))){
        if(parseInt(values.steps) > parseInt(stepTarget)){
            return(
                <div className="target_indicator" style={styles}>
                    <img src={require('../../assets/icons/target_green.png')} alt="target" />
                </div>
            );
        } else {
            return null
        }
    } else {
        return null;
    }
}

export default StepBubble;