import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Authentication from '../../classes/Authentication.js';
import colors from '../../Colors.js';
import './Header.css';

const Header = () => {
    const [display, setDisplay] = useState("none");
    const [rotated, setRotated] = useState("rotated");
    let history = useHistory();

    const toggleBurger = () => {
        (display === "none") ? openBurger() : closeBurger();
    }
    
    const openBurger = () => {
        setRotated("");
        setDisplay("block");
    }

    const closeBurger = () => {
        setRotated("rotated");
        setDisplay("none");

    }

    const signOut = async () => {
        let auth = new Authentication();
        let signout = auth.signOut();

        if(signout){
            history.push("/login");
        }
    }

    const steps = () => {
        setDisplay("none");
        setRotated("rotated");
        history.push("/steps");
    }

    const home = () => {
        setDisplay("none");
        setRotated("rotated");
        history.push("/home");
    }

    return(
        <div>
            <header>
                <div className="logo_container">
                    <div className="logo_wrapper">
                        <img src={require('../../assets/logos/rtg_black.svg')} alt="logo" />
                    </div>
                </div>
                <div className="burger_container" onClick={() => toggleBurger()}>
                    <h4 className={rotated} style={{color: colors.black}}>|||</h4>
                </div>
                
            </header>
            <div className="burgernav" style={{display: display, paddingBottom: '20px'}}>
                
                <ul onClick={home}><li>Home</li></ul>
                <ul onClick={steps}><li>Steps</li></ul>
                <Link to="/about">
                    <ul><li>About</li></ul>
                </Link>
                <Link to="/settings">
                    <ul><li>Settings</li></ul>
                </Link>
                
            </div>
        </div>
    );
}

export default Header;