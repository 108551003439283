import React, { useState } from 'react';
import Input from '../../input/Input.js';
import Button from '../../button/Button.js';
import Authentication from '../../../classes/Authentication.js';

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const updatePassword = () => {
        if(password === confirmPassword){
            let auth = new Authentication();
            auth.resetPassword(password)
        }
    }

    return(
        <div>
            <Input password={true} onChange={setPassword} icon="password" label="New Password" placeholder="password" />
            <Input password={true} onChange={setConfirmPassword} icon="password" label="Confirm Password" placeholder="password" />
            <div style={{marginTop: "30px"}}>
                <Button onClick={updatePassword} label="Change" />
            </div>
        </div>
    );
}

export default ResetPassword;
