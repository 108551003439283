import firebase from 'firebase/app';
import DateHelpers from './DateHelpers.js';
import 'firebase/auth';

class Database {
    constructor(){
        // Set UID as global
        this.uid = this.getUid();
    }

    // Returns the current users UID
    getUid(){
        let user = firebase.auth().currentUser;
        if(user === null){
            //console.log("No UID present, plese sign in again");
            return false;
        } else {
            return user.uid;
        }
    }

    // Save data to firebase for a given date
    async dataEntry(sports, casino, other, date){
        let week = await this.getWeekFromDate(date);
        let validate = [sports.spend, sports.time, casino.spend, casino.time, other.spend, other.time];

        // NAN produced when user doesn't enter any values to text input formated this to 0
        for(var i = 0; i < validate.length; i++){
            if(isNaN(validate[i]) | validate[i] === undefined | validate[i] === null){
                validate[i] = 0;
            }
        }

        // set nodata to false, meaning a zero is really a zero...
        firebase.database().ref(`users/${this.uid}/entries/week${week}/${date}`).set({
            sports: { spend: parseInt(sports.spend), time: parseInt(sports.time) },
            casino: { spend: parseInt(casino.spend), time: parseInt(casino.time) },
            other: { spend: parseInt(other.spend), time: parseInt(other.time) },
            nodata: false
        })
        return true;
    }

    /**
     * getWeekFromData(date)
     * 
     * Return what week number a given date is within the database
     * The week numbers are arbitrary and relate to the number of weeks the
     * user has been active.
     * 
     * @todo - search arrays instead of for loop
     * 
     */ 
    async getWeekFromDate(date){
        let results = await this.getAllEntries();
        if (results === undefined){
            await this.createNewWeekEntry(1);
            results = await this.getAllEntries();
        }
        let keys = Object.keys(results);
        var loopWeek = 0;
        var week = 0;
    
        // This loop will search the dates held and find
        // the number of the week where that date exists.
        // It also identifies where the data is not there.
        // @todo - rewrite to use array search functions.
        for(var i = 0; i < keys.length; i++){
            let dayKey = Object.keys(results[keys[i]]);
            for(var j = 0; j < dayKey.length; j++){
                if(dayKey[j] === date){
                    //week10 bug caused by line below - assumed weeks were in order
                    //week = i + 1;
                    week = keys[i].replace('week','');
                }
            }
            loopWeek++;
        }

        if(week === 0){
            week = await this.createNewWeekEntry(loopWeek + 1);
        }
        
        return week;
    }

    async createNewWeekEntry(week){
        let monday = DateHelpers.getMonday(new Date());
        let dates = this.createCurrentWeekObject(monday);
        let weekKey = `week${week}`;

        await firebase.database().ref(`users/${this.uid}/entries/`).update({
            [weekKey]: dates
        })

        let targets = {
            sports: { spend: 0, time: 0 },
            casino: { spend: 0, time: 0 },
            other: { spend: 0, time: 0 }
        }

        await firebase.database().ref(`users/${this.uid}/entries/week${week}`).update({
            targets: targets
        })
        return week;
    }

    // return results from a given query in object format
    async returnResults(date){
        let d = DateHelpers.returnFormattedDate(date);
        return await firebase.database().ref(`users/${this.uid}/entries/${d}`)
        .once('value')
        .then((dayResult) => {
            if(dayResult.val() === undefined | dayResult.val() === null){
                return {date: null}
            } else {
                return dayResult.val()
            }
        })
    }

    // returns all entries for user to fill timeline
    async getAllEntries(){
        return await firebase.database().ref(`users/${this.uid}/entries`)
        .once('value')
        .then(async (entries) => {
            if(entries.val() === undefined | entries.val() === null){
            } else {
                let results = entries.val();
                return results;
            }
        })
    }

    // Create new user entry in database for new sign ups
    async initialiseUserEntries(uid, name, email){
        let currentDate = new Date();
        let startDate = DateHelpers.getMonday(currentDate);
        let dates = this.createCurrentWeekObject(startDate);
    
        let result = await firebase.database().ref('users/' + uid).set({
            name: name,
            email: email,
            entries: {
                week1: dates
            }
        }).then(async () => {
            await firebase.database().ref(`users/${uid}/entries/week1`).update({
                targets: {
                    spend: 0,
                    time: 0
                }
            })
            return true;
        }).catch(error => {
            //console.log("Error: "+error);
            return false;
        });
        return result;
    }

    //returns only the users entries from the current week
    createCurrentWeekObject(startDate){
        var dates = {};
        for(var i = 0; i < 7; i++){
            let formattedDate = DateHelpers.returnFormattedDate(startDate)
            dates[formattedDate] = {
                sports: { spend: 0, time: 0 },
                casino: { spend: 0, time: 0 },
                other: { spend: 0, time: 0 },
                nodata: true
            }
            startDate.setDate(startDate.getDate() + 1)
        };

        return dates;
    }

    async getWeekTotal(date){
        let formDate = DateHelpers.returnFormattedDate(date);
        let week = await this.getWeekFromDate(formDate);

        return await firebase.database().ref(`users/${this.uid}/entries/week${week}`)
        .once('value')
        .then((snapshot) => {
            let results = snapshot.val();
            let days = Object.keys(results);

            var spend = 0;
            var time = 0;
            
            for(var i = 0; i < days.length; i++){
                let todaytime = 0;
                if(days[i] !== "targets"){
                    spend += parseInt(results[days[i]].sports.spend);
                    spend += parseInt(results[days[i]].casino.spend);
                    spend += parseInt(results[days[i]].other.spend);
                    todaytime += parseInt(results[days[i]].sports.time);
                    todaytime += parseInt(results[days[i]].casino.time);
                    todaytime += parseInt(results[days[i]].other.time);
                }
                //only 24 hours in a day
                todaytime = (todaytime > 1440) ? 1440 : todaytime;
                time += todaytime;
            }
            return {spend: spend, time: time};
        })
    }

    async getWeekStepTotal(date){
        let formDate = DateHelpers.returnFormattedDate(date);
        let week = await this.getWeekFromDate(formDate);

        return await firebase.database().ref(`users/${this.uid}/entries/week${week}`).once('value')
        .then((snapshot) => {
            let results = snapshot.val();
            let days = Object.keys(results);
            var steps = 0;
            
            for(var i = 0; i < days.length; i++){
                if(days[i] !== "targets"){
                    if(results[days[i]].steps !== undefined){
                        steps += parseInt(results[days[i]].steps);
                    }
                }
            }
            return steps;
        })
    }

    async updateNotes(date, notes){
        let week = await this.getWeekFromDate(date);
        await firebase.database().ref(`users/${this.uid}/entries/week${week}/${date}`).update({
            notes: notes
        })
    }

    async getNotes(date){
        let formatDate = await DateHelpers.returnFormattedDate(date);
        let week = await this.getWeekFromDate(formatDate);

        return await firebase.database().ref(`users/${this.uid}/entries/week${week}/${formatDate}`).once('value')
        .then((snapshot) => {
            let result = snapshot.val();

            return result.notes;
        })
    }

    async getFirstDayOfGivenWeek(week){
        return await firebase.database().ref(`users/${this.uid}/entries/week${week}`)
        .once('value')
        .then((snapshot) => {
            let results = snapshot.val();
            if(results !== undefined | results !==  null){
                let dates = Object.keys(results);
                return dates;
            } else {
                return null;
            }
        })
    }

    updateTargets(week, spend, time){
        firebase.database().ref(`users/${this.uid}/entries/week${week}/targets`).update({
            spend: spend,
            time: time
        })
    }

    updateStepTarget(week, steps){
        firebase.database().ref(`users/${this.uid}/entries/week${week}/targets`).update({
            steps: steps
        })
    }

    async returnTargets(week){
        return await firebase.database().ref(`users/${this.uid}/entries/week${week}/`)
        .once('value')
        .then((snapshot) => {
            let results = snapshot.val();
            return results.targets
        })
    }

    async returnTotalWeeks(){
        return await firebase.database().ref(`users/${this.uid}/entries/`)
        .once('value')
        .then((snapshot) => {
            let results = snapshot.val();
            let keys = Object.keys(results);
            return keys.length;
        })
    }

    async updatePersonalDetails(name, studyid){
        return await firebase.database().ref(`users/${this.uid}`).update({
            name: name,
            studyid: studyid
        })
    }
}

export default Database;