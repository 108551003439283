import React, { useState } from 'react';
import Input from '../../input/Input.js';
import Button from '../../button/Button.js';
import Database from '../../../classes/Database.js';

const UpdateDetails = () => {
    const [name, setName] = useState("");
    const [studyid, setStudyId] = useState("");

    const updateDetails = () => {
        let db = new Database();
        db.updatePersonalDetails("Anon", studyid);
    }

    return(
        <div>
            {/*<Input onChange={setName} icon="profile" label="Name" placeholder="Joe Bloggs" />*/}
            <Input onChange={setStudyId} icon="id" label="Study ID" placeholder="study id" />
            <div style={{marginTop: "30px"}}>
                <Button onClick={updateDetails} label="Update" />
            </div>
        </div>
    );
}

export default UpdateDetails;