import React, { useState, useRef } from 'react';
import Input from '../../input/Input.js';
import Minheader from '../../minheader/Minheader.js';
import Button from '../../button/Button.js';
import { useHistory } from 'react-router-dom';
import Background from '../../../assets/backgrounds/pexels-fabricio-trujillo-54567.jpg';
import Authentication from '../../../classes/Authentication.js';
import Infosheet from '../../informationsheet/infosheet.js';
import './Signup.css';
import Footer from '../../footer/Footer.js';
import Checkbox from '../../checkbox/Checkbox.js';

const Signup = (props) => {
    const [name, setName] = useState();
    const [checkedQ1, setCheckedQ1] = useState(false);
    const [checkedQ2, setCheckedQ2] = useState(false);
    const [checkedQ1x, setCheckedQ1x] = useState(false);
    const [checkedQ2x, setCheckedQ2x] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [consentStage, setConsentStage] = useState(0);

    let history = useHistory();

    const signupAttempt = async () => {
        let auth = new Authentication();
        let status = await auth.signupAttempt("Anon", email, password, confirmPassword);

        if(status !== true){
            return;
        } else if (status === true){
            history.push('/login');
        } else {
            return;
        }
    }

    let signupForm = <div>
        {/*<Input onChange={setName} value={name} label="Name" placeholder="Joe Bloggs" icon="profile" />*/}
        <Input onChange={setEmail} value={email} label="Email Address" placeholder="email" icon="email" />
        <Input password={true} onChange={setPassword} value={password} label="Password" placeholder="password" icon="password" />
        <Input password={true} onChange={setConfirmPassword} value={confirmPassword}    label="Confirm Password" placeholder="confirm password" icon="password" />
        
        <div style={{marginTop: "30px", cursor: "pointer"}} className="login">
            <Button label="Sign Up!" onClick={signupAttempt} />
        </div>
    </div>

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "/";

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()   

    const confirm = () => {
        setConsentStage(consentStage + 1);
        executeScroll();
    }

    const nothing = () => {
       
    }

    const goBack = () => {
        setConsentStage(consentStage - 1);
        executeScroll();
    }

    const consent = () => {
        setConsentStage(consentStage + 1);
    }

    const consentNot = () => {
        history.push('/');
    }

    const confirmButton =
            <div style={{marginTop: "30px", cursor: "pointer"}} className="login">
                { (consentStage !==2) &&
                    <Button label="Continue" onClick={confirm} />
                }
                {
                    (consentStage===2 && checkedQ1 && checkedQ2 && !checkedQ1x && !checkedQ2x) &&
                    <Button label="Continue" onClick={confirm} />
                }
            </div>

    const backButton =
            <div style={{marginTop: "30px", cursor: "pointer"}} className="login">
                <Button label="Back" onClick={goBack} buttonColor={"white"} />
            </div>
        
 

    const buttons = [
        <div style={{marginBottom: "30px"}}>{confirmButton}</div>,
        <div style={{marginBottom: "30px"}}>{confirmButton} {backButton}</div>,
        <div style={{marginBottom: "30px"}}>{confirmButton} {backButton}</div>,
        signupForm 
    ];

    const handleCheck = (value, question) => {
        if (question === "q1") { 
            setCheckedQ1(!checkedQ1); 
        }
        if (question === "q2") { 
            setCheckedQ2(!checkedQ2); 
        }
        if (question === "q1x") { 
            setCheckedQ1x(!checkedQ1x); 
        }
        if (question === "q2x") { 
            setCheckedQ2x(!checkedQ2x); 
        }
    }

    return(
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`}}>
            <div className="content_wrapper" id="signup">
            <div ref={myRef} />
                <Minheader heading="" />

                {/* <Infosheet consentStage={consentStage} />  */}

                {/* (consentStage===2) &&
                    <div className="consent_checkboxes">
                        <div className="checkbox">
                            <Checkbox onChange={handleCheck} id="q1"/> 
                            <label htmlFor="q1">I consent to the interviews being audio recorded</label>
                        </div>
                        <div className="checkbox">
                            <Checkbox onChange={handleCheck} id="q1x"/> 
                            <label htmlFor="q1x">I do not consent to the interviews being audio recorded</label>
                        </div>
                        <div className="checkbox">
                            <Checkbox onChange={handleCheck} id="q2"/> 
                            <label htmlFor="q2" >I agree to take part in this research study</label>
                        </div>
                        <div className="checkbox">
                            <Checkbox onChange={handleCheck} id="q2x"/> 
                            <label htmlFor="q2x">I do not agree to take part in this study</label>
                        </div>
                    </div>
                */}
            
                { /* buttons[consentStage] */}
                {signupForm}
   
                <Footer />
            </div>
        </div>
    );
}

export default Signup;