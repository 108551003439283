// Simple helper class for formatting the entries within the timeline bubbles
import DateHelpers from './DateHelpers.js';

const sportIcon = require('../assets/icons/sports_white.png');
const casinoIcon = require('../assets/icons/casino_white.png');
const otherIcon = require('../assets/icons/horse_white.png');
const totalIcon = require('../assets/icons/total_white.png');

class EntryHelpers {
    // return the amount of bubble rows required from given entries
    static getRowsFromEntries(entries){
        let keys = Object.keys(entries);
        var rows = 0;
        for(var i = 0; i < keys.length; i++){
            //if(entries[keys[i]].spend !== 0 | entries[keys[i]].time !== 0 ){
                rows++;
            //}
        }
        return rows;
    }  

    // return only the entries with non zero values
    static formatDailyEntries(entries){
        let keys = Object.keys(entries);
        var formattedEntries = [];
 
        for(var i = 0; i < keys.length; i++){
            //if(entries[keys[i]].spend !== 0 | entries[keys[i]].time !== 0){
                let category = keys[i];
                formattedEntries.push({
                    category: category,
                    spend: entries[keys[i]].spend,
                    time: entries[keys[i]].time
                });
            //}
        }
        return formattedEntries;
    }



    static returnImageIcon(entry){
        let icons = {sports: sportIcon, casino: casinoIcon, other: otherIcon, total: totalIcon};
        return icons[entry.category];
    }

    static getDaySpendTotal(entry){
        var total = 0;
        let keys = Object.keys(entry);
        for(var i = 0; i < keys.length; i++){
            if(keys[i] !== "steps" && keys[i] !== "notes" && keys[i] !== "nodata"){
                if(typeof(entry[keys[i]]) !== "string")
                    total += entry[keys[i]].spend;
            }
        }
        return total;
    }

    static getDayTimeTotal(entry){
        var total = 0;
        let keys = Object.keys(entry);
        for(var i = 0; i < keys.length; i++){
            if(keys[i] !== "steps" && keys[i] !== "notes" && keys[i] !== "nodata"){
                if(typeof(entry[keys[i]]) !== "string")
                    total += entry[keys[i]].time;
            }
        }
        return total;
    }

    static sortArray(entries){
        let dateArray = Object.keys(entries);
        let formattedDateArray = [];
        for(var i = 0; i < dateArray.length; i++){
            if(dateArray[i] !== "targets"){
                formattedDateArray.push({date: DateHelpers.dateToTimestamp(dateArray[i])});
            }
        }

        const sortedDates = formattedDateArray.sort((a, b) => b.date - a.date)
        var sortAndFormattedArray = [];
        for(var j = 0; j < sortedDates.length; j++){
            sortAndFormattedArray.push(DateHelpers.returnFormattedDate(sortedDates[j].date));
        }

        var newObj = {};
        for(var y = 0; y < sortAndFormattedArray.length; y++){
            newObj[sortAndFormattedArray[y]] = entries[sortAndFormattedArray[y]];
        }

        return newObj;
    }
}

export default EntryHelpers;