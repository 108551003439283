import React, { useEffect, useState } from 'react';
import Header from '../../header/Header.js';
import Background from '../../../assets/backgrounds/pexels-fabricio-trujillo-54567.jpg';
import './About.css';
import Infosheet from '../../informationsheet/infosheet.js'
import colors from '../../../Colors.js';
import Footer from '../../footer/Footer.js';
import {useHistory} from 'react-router-dom'; 
import Authentication from '../../../classes/Authentication.js';


const About = () => {
    let history = useHistory();
    const [authStatus, setAuthStatus] = useState(false);
 
    useEffect(() => {
        let auth = new Authentication();
        let isMounted = true;

        const updateAuthStatus = (status) => {
            if (isMounted) {
                setAuthStatus(status);
            }
        }
        auth.checkAuthenticated(history, updateAuthStatus);

        return () => { isMounted = false };

    }, [history])

    return(
        
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`}}>
            {(authStatus === true) && 
            <div className="content_wrapper">
                <Header />

                    <div className="about_wrapper">
                        {/*<Infosheet consentStage={0} />  
                        <Infosheet consentStage={1} />  
                        <Infosheet consentStage={2} />  */}
                        <Infosheet consentStage={1} />
                    </div>
                <Footer />        
            </div>
            }
        </div>
        
    );
}

export default About;