// Simple helper class for date manipulation and conversion

const dayArray = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];

class DateHelpers {
    // given a date from firebase (string) return the timestap for access to methods like getDay() etc
    static dateToTimestamp(date){
        var format = date.split("-");
        let day = format[0];
        let month = format[1];
        let year = format[2];

        return new Date(month+"/"+day+"/"+year);
    }

    // Strip [""] charachters from firebase date string
    static sanitizeDBDate(date){
        let leftParse = date.replace('["', '');
        let rightParse = leftParse.replace('"]', '');
        return rightParse;
    }


    // Take in UTC timestamp and return a firebase friendly string format
    static returnFormattedDate(date){
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear(); 

        if(day < 10)
            day = "0"+day;
        
        if(month < 10)
            month = "0"+month;
    
        return day+"-"+month+"-"+year;
    }


    // returns the amount of weeks between a given date and the current date
    static returnWeekCount(givenDate){
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - givenDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        let weeks = Math.round(diffDays / 7);

        if(weeks < 1)
            weeks = 1;

        return weeks;
    }


    // returns the first day of a given week
    static getMonday(date) {
        date = new Date(date);
        var day = date.getDay(),
        diff = date.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
            
        return new Date(date.setDate(diff));
    }

    static returnFormattedTime(time){
        if(time < 60){
            return `${time} mins`;
        } else {
            return `${(time / 60).toFixed(1)} hrs`;
        }
    }

    static getWeekOfMonth(date){
        const startWeekDayIndex = 1;
        const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const firstDay = firstDate.getDay();

        let weekNumber = Math.ceil((date.getDate() + firstDay) / 7);
        if(startWeekDayIndex === 1){
            if(date.getDay() === 0 && date.getDate() > 1){
                weekNumber -= 1;
            }

            if(firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1){
                weekNumber += 1;
            }
        }
        return weekNumber;
    }

    static verifyPastDate(date){
        let today = new Date();
    
        if(date > today){
            return false;
        } else {
            return true;
        }
    }

    static returnDay(date){
        let dateTs = DateHelpers.dateToTimestamp(date);
        let todayTs = new Date();
        let givenDate = `${dateTs.getDate()}/${dateTs.getMonth()}/${dateTs.getYear()}`;
        let today = `${todayTs.getDate()}/${todayTs.getMonth()}/${todayTs.getYear()}`
    
        if(givenDate === today){
            return "Today";
        } else {
            return dayArray[dateTs.getDay()];
        }
    }
}

export default DateHelpers;