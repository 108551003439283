import React, { useEffect, useState } from 'react';
import Header from '../../header/Header.js';
import TimelineWeek from '../../timelineweek/TimelineWeek.js';
import Authentication from '../../../classes/Authentication.js';
import Database from '../../../classes/Database.js';
import ScaleLoader from 'react-spinners/ScaleLoader';
import DateHelpers from '../../../classes/DateHelpers.js';
import Background from '../../../assets/backgrounds/pexels-fabricio-trujillo-54567.jpg';
import {useHistory} from 'react-router-dom';
import "./Home.css";
import Footer from '../../footer/Footer.js';
import {Link} from 'react-router-dom';


const Home = ({location}) => {
    let type = (location.pathname !== "/steps") ? true : false;
    const [entries, setEntries] = useState(false);
    //const [noUID, setNoUID] = useState(false);
    const [weeks, setWeeks] = useState(0);
    const [authStatus, setAuthStatus] = useState(false);
    var history = useHistory();


    useEffect(() => {
        
        let auth = new Authentication();
        let isMounted = true;

        const updateAuthStatus = (status) => {
            if (isMounted) {
                setAuthStatus(status);
            }
        }
        auth.checkAuthenticated(history, updateAuthStatus);

        return () => { isMounted = false };

    }, [history])

    useEffect (() => {
        let db = new Database();

        const updateEntries = async () => {
            if(db.uid === false){ 
                setEntries(true);
                history.push("/error");
                //history.replace("/home");
            } else {

                let ts = DateHelpers.returnFormattedDate(new Date());
                let myWeeks = await db.getWeekFromDate(ts);
                setWeeks(myWeeks);
                
                let entries = await db.getAllEntries();
                setEntries(entries);
            }
        }
        updateEntries();
    },[])


    return(
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`}}>
            {(authStatus === true) && 
            <div className="content_wrapper">
                <Header />
                {!entries && 
                    <div style={{minHeight:'700px'}} >
                    <div style={{position: 'absolute', zIndex: 100, flexDirection: 'row', display: 'flex', top: 0, bottom: 0, left: 0, right: 0, alignItems: 'center', justifyContent: 'center'}}>
                        <Loading />
                        <h2 style={{paddingLeft: "10px"}}>Loading</h2>
                    </div>
                    </div>
                }
                
                {!type &&
                    <div style={{width:'85%', border:'2px solid red', margin: 'auto', marginTop: '20px'}}>
                        <p style={{fontFamily: 'monospace', fontSize: '2em', textAlign: 'center'}}>Step counting is not availble in this version of the app</p>
                    </div>
                }
                {returnWeeks(entries, weeks, type)}
                
                <Footer />
            </div>
            }
        </div>
    );
}

const Loading = () => {
    return(
            <ScaleLoader 
                loading={true}
                color="#000"
                size={150}
            />
    );
}

const returnWeeks = (entries, weeks, type) => {

    if(entries === false){
        // TODO - show instruction screen

        return null;
    } else {

        if (weeks === 0) {
            return [<p key={1}>Error: could not load data</p>]
        }

        let jsx = [];
        for(var i = 0; i < weeks; i++){
            jsx.push(<TimelineWeek key={i} number={i} entries={entries} week={i + 1} gambling={type} />);
        }
        jsx.sort().reverse();
        return jsx;
    }
}

export default Home;