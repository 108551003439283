import React from 'react';
import './SummaryIcon.css';

const icons = [{icon: "steps", image: require("../../assets/icons/shoe_white.png")},{icon: "money", image: require("../../assets/icons/money_white.png")},{icon: "time", image: require("../../assets/icons/time_white.png")},{icon: "goals", image: require("../../assets/icons/target_white.png")},]

const SummaryIcon = (props) => {
    var icon;
    var click;
    if(props.onClick === undefined | props.onClick === null){
        // click = () => console.log("icon clicked"); 
    } else {
        click = props.onClick
    }

    let summary_icon_class = "summary_icon";
    if (props.label === "Targets") {
        summary_icon_class = "summary_icon summary_icon_right";
    }

    for(var i = 0; i < icons.length; i++){
        if(icons[i].icon === props.icon){
            icon = icons[i].image;
        }
    }

    return(
        <div className={summary_icon_class} onClick={click}>
            <img src={icon} alt={props.label} />
            <div className="money_summary" style={{padding: "10px 0"}}>
                <h2 style={{textAlign: "center", color: props.labelColor, fontSize: 20}}>{props.label}</h2>
            </div>
        </div>
    );
}

export default SummaryIcon;