import React, { useEffect, useState } from 'react';
import Header from '../../header/Header.js';
import Background from '../../../assets/backgrounds/pexels-fabricio-trujillo-54567.jpg';
import './Error.css';
import Footer from '../../footer/Footer.js';
import {useHistory} from 'react-router-dom'; 
import Authentication from '../../../classes/Authentication.js';
import {Link} from 'react-router-dom';


const Error = () => {
    let history = useHistory();
    const [authStatus, setAuthStatus] = useState(false);
 
    useEffect(() => {
        let auth = new Authentication();
        let isMounted = true;

        const updateAuthStatus = (status) => {
            if (isMounted) {
                setAuthStatus(status);
            }
        }
        auth.checkAuthenticated(history, updateAuthStatus);

        return () => { isMounted = false };

    }, [history])


    return(
        
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`}}>
            {(authStatus === true) && 
            <div className="content_wrapper">
                <Header />

                    <div className="error_wrapper">
                        <p>Error loading data</p>
                        <ul>
                        <Link to="/home">
                            <li>Retry</li>
                        </Link>
                        </ul>

                    </div>
                <Footer />        
            </div>
            } hi
        </div>
        
    );
}

export default Error;