import React from 'react';
import colors from '../../Colors.js';
import './Input.css';

const iconsPurple = [{icon: "id", image: require('../../assets/icons/id_purple.png')},{icon: "profile", image: require('../../assets/icons/profile_purple.png')},{icon: "email", image: require('../../assets/icons/email_purple.png')},{icon: "password", image: require('../../assets/icons/password_purple.png')}];
//const iconsWhite = [{icon: "id", image: require('../../assets/icons/id_white.png')},{icon: "profile", image: require('../../assets/icons/profile_white.png')},{icon: "email", image: require('../../assets/icons/email_white.png')},{icon: "password", image: require('../../assets/icons/password_white.png')}];

const Input = (props) => {
    var icon;
    var password = false;

    if (props.password === true){
        password = true;
    }

    for(var i = 0; i < iconsPurple.length; i++){
        if(iconsPurple[i].icon === props.icon){
            icon = iconsPurple[i].image;
        }
    }

    const updateText = (value) => {
        props.onChange(value);
    }

    return(
        <div className="input_box_container">
            {/*<h1 style={{color: props.labelColor, fontSize: 20}}>{props.label}</h1>*/}
            <div className="input_box">
                <div className="input_image">
                    <img src={icon} alt="Profile" />
                </div>
                {!password ? 
                    <input style={{backgroundColor: colors.black, color: colors.yellow}} onChange={(e) => updateText(e.target.value)} type="text" placeholder={props.placeholder} /> 
                    :<input style={{backgroundColor: colors.black, color: colors.yellow}} onChange={(e) => updateText(e.target.value)} type="password" placeholder={props.placeholder} />
                }
            </div>
        </div>
    );
}

export default Input;