import React, { useState } from 'react';
import Colors from '../../Colors.js';
import CancelButton from '../cancelbutton/CancelButton.js';
import Database from '../../classes/Database.js';
import Button from '../button/Button.js';
import './TargetModalSteps.css';

const TitleRow = () => {
    return(
        <div className="entry_modal_row" style={{flexWrap: 'wrap'}}>
            <h1 style={{flexBasis: '100%', color: Colors.yellow, textAlign: 'center'}}>Daily Targets</h1>
        </div>
    );
}

const EntryRow = ({updateSteps}) => {
    return(
        <div className="entry_modal_row">
            <div className="entry_modal_column" style={{ width: '70%'}}>
                <h3 style={{color: Colors.yellow}}>Steps</h3>
            </div>
            <div className="entry_modal_column" style={{borderRadius: 40, width: '70%'}}>
                <input onChange={(event) => updateSteps(event.target.value)} placeholder="0" style={{backgroundColor: Colors.yellow}} type="text" className="entry_modal_input" />
            </div>
        </div>
    );
}

const TargetModal = ({week, visible, onClick, updateTargets}) => {
    let display = visible ? "block" : "none";
    const [steps, setSteps] = useState(0);

    const updateEntries = () => {
        let db = new Database();
        db.updateStepTarget(week, steps);
        updateTargets(week);
        onClick();
    }

    return(
        <div className="entry_modal" onClick={(event) => event.stopPropagation()} style={{position: 'fixed', overflow: 'scroll', height: '100%', bottom: 0, backgroundColor: Colors.black, display: display}}>
            <TitleRow />
            <EntryRow updateSteps={setSteps} />
            <div style={{marginTop: "50px"}}>
                <Button label="Update" onClick={updateEntries} />
            </div>
            <div className="plusbutton_container">
                <CancelButton onClick={() => onClick()} color={Colors.black} bgColor={Colors.yellow} type="close" />
            </div>
        </div>
    );
}

export default TargetModal;