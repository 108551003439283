/**
 * 
 * TargetModal
 * 
 * Set targets for spend and time
 * 
 */

import React, { useState, useEffect } from 'react';
import Colors from '../../Colors.js';
import CancelButton from '../cancelbutton/CancelButton.js';
import Database from '../../classes/Database.js';
import Button from '../button/Button.js';
import './TargetModal.css';

const TitleRow = ({prevSpend, prevTime, week}) => {

    // Assumption - the first week is week 1.
    let lastWeek = (week <= 1) 
        ? <div></div>
        : <p>Last week your daily spend limit was £{prevSpend} and your daily thinking time limit was {prevTime} minutes</p>;

    return(
        <div className="entry_modal_row" style={{flexWrap: 'wrap', marginBottom: '0px'}}>
            <h1 style={{flexBasis: '100%', color: Colors.black, textAlign: 'center', marginLeft: '0px'}}>Targets</h1>
            <p>Enter your daily limits for this week for spending on gambling and thinking about gambling.</p>
            {lastWeek}
            <div className="entry_modal_column">
                <h4 style={{color: Colors.black, marginBottom: '5px'}}>Daily spend £</h4>
            </div>
            <div className="entry_modal_column">
                <h4 style={{color: Colors.black, marginBottom: '5px'}}>Daily time</h4>
            </div>
        </div>
    );
}

const EntryRow = ({updateSpend, updateTime, spend, time}) => {
    return(
        <div className="entry_modal_row" style={{flexWrap: 'wrap', marginTop: '0px'}}>
            <div className="entry_modal_column" style={{borderRadius: 40, width: '70%'}}>
                <input onChange={(event) => updateSpend(event.target.value)} value={spend} placeholder="£" style={{backgroundColor: Colors.black, color: Colors.yellow}} type="number" min="0" max="9999" step=".01" className="entry_modal_input" />
            </div>
            <div className="entry_modal_column">
                <select value={time} onChange={(event) => updateTime(event.target.value)} style={{height: 50, width: '70%',paddingLeft: 15, borderRadius: 40, backgroundColor: Colors.black, color: Colors.yellow, fontSize: '18px', textAlign: 'center'}}>
                    <option value={0}>None</option>
                    {returnTimeEntries(time)}
                </select>
            </div>
        </div>
    );
}

const SummaryRow = ({spend, time}) => {
    return(
        <div>
            <div className="entry_modal_row" style={{flexWrap: 'wrap', marginTop: '0px', marginBottom: '0px'}}>
                <div className="entry_modal_column">
                    <p style={{color: Colors.black, margin: '0px'}}>Weekly: £{(spend * 7).toFixed(2)}</p>
                </div>
                <div className="entry_modal_column">
                    <p style={{color: Colors.black, margin: '0px'}}>Weekly: {((time * 7)/60).toFixed(1)} hours</p>
                </div>
            </div>
        </div>
    );
}

const returnTimeEntries = () => {
    let options = [];
    var timeIncrement = 0;

    for(var i = 0; i < 52; i++){
        if(timeIncrement < 60){
            let minute = timeIncrement += 10;
            options.push(<option key={i} value={minute}>{minute} Mins</option>)
        } else { 
            let hour = ((timeIncrement += 30) / 60).toFixed(1);
            options.push(<option key={i} value={hour * 60}>{hour} Hrs</option>)
        }
    }

    return options;
}

/**
 * 
 * TargetModal
 * 
 */
const TargetModal = ({week, steps, visible, onClick, updateTargets}) => {
    let display = visible ? "block" : "none";

    const [spend, setSpend] = useState(0);
    const [time, setTime] = useState(0);
    const [prevSpend, setPrevSpend] = useState(0);
    const [prevTime, setPrevTime] = useState(0);

    // Load spend target in modal
    useEffect(() => {
        let db = new Database();

        let targets = db.returnTargets(week);

        let targetsPrevious = (week > 1) ? db.returnTargets(week-1) : undefined;


        targets.then(res => {
            (res.spend === undefined) ? setSpend(0) : setSpend(Number(res.spend));
            (res.time === undefined) ? setTime(0) : setTime(Number(res.time));
        });

        if (targetsPrevious !== undefined) {
            targetsPrevious.then(res => {
                (res.spend === undefined) ? setPrevSpend(0) : setPrevSpend(Number(res.spend));
                (res.time === undefined) ? setPrevTime(0) : setPrevTime(Number(res.time));
            });
        }
        
    },[]);

    if(steps === undefined)
        steps = false;

    const updateEntries = () => {
        let db = new Database();
        db.updateTargets(week, spend, time);
        updateTargets(week);
        onClick();
    }

    return(
        <div className="entry_modal" onClick={(event) => event.stopPropagation()} style={{position: 'fixed', overflow: 'scroll', height: '100%', bottom: 0, backgroundColor: Colors.white, display: display}}>
            <TitleRow prevSpend={prevSpend} prevTime={prevTime} week={week}/>
            <EntryRow updateSpend={setSpend} updateTime={setTime} spend={spend} time={time}/>
            <SummaryRow spend={spend} time={time}/>
            <div style={{marginTop: "50px"}}>
                <Button label="Update" onClick={updateEntries} />
            </div>
            <div className="cancelbutton_container">
                <CancelButton onClick={() => onClick()} color={Colors.black} bgColor={Colors.yellow} type="close" />
            </div>
        </div>
    );
}

export default TargetModal;
