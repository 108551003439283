import React, { useState, useEffect, useRef } from 'react';
import DateHelpers from '../../classes/DateHelpers.js';
import EntryHelpers from '../../classes/EntryHelpers.js';
import PlusButton from '../../components/plusbutton/PlusButton.js';
import Database from '../../classes/Database.js';
import colors from '../../Colors.js';

const dayArray = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];

const ValueBubble = ({values, toggleEntryModal, targets, total, expanded, expandFunc, date, jsx}) => {
    let dow = DateHelpers.dateToTimestamp(date);
    let isPast = verifyPastDate(dow);
    let display = isPast ? null : "none";
    
    return(
        <div>
        <div style={{marginBottom: "5px", color: colors.black, display: display}} className="timeline_row">
            <h4 style={{color: colors.black, flexBasis: "100%", margin: 0,marginLeft: "60px", padding: 0, fontSize: 20 }}>{date}</h4>
        </div>
        <div style={{marginBottom: "40px", color: colors.black, display: display}} className="timeline_row">
            <div className="entry_dot" style={{backgroundColor: colors.yellow}}><h1 style={{fontSize: 18, marginLeft: 0}}>{returnDay(date)}</h1></div>
            <div style={{backgroundColor: colors.yellow}} className={!expanded ? "entry_bubble_collapsed" : "entry_bubble_expanded"} onClick={(event) => expandFunc(event)}>
                <BubbleContent date={date} toggleEntryModal={toggleEntryModal} values={values} jsx={jsx} total={total} expanded={expanded} />
                {/* <TargetIndicator date={date} targets={targets} values={values} /> */}
            </div>
        </div>
        </div>
    ); 
}

const TargetIndicator = ({targets, values, date}) => {
    let spendTarget = targets.spend;
    let timeTarget = targets.time;

    let results = {spend: EntryHelpers.getDaySpendTotal(values), time: EntryHelpers.getDayTimeTotal(values)}
    
    if(results.spend < spendTarget && results.time < timeTarget){
        return(
            <div className="target_indicator" style={{backgroundColor: colors.yellow}}>
                <img src={require('../../assets/icons/target_green.png')} alt="target" />
            </div>
        );
    } else {
        return null
    }
}

const returnDay = (date) => {
    let dateTs = DateHelpers.dateToTimestamp(date);
    let todayTs = new Date();
    let givenDate = `${dateTs.getDate()}/${dateTs.getMonth()}/${dateTs.getYear()}`;
    let today = `${todayTs.getDate()}/${todayTs.getMonth()}/${todayTs.getYear()}`

    if(givenDate === today){
        return "Today";
    } else {
        return dayArray[dateTs.getDay()];
    }
}

const verifyPastDate = (date) => {
    let today = new Date();

    if(date > today){
        return false;
    } else {
        return true;
    }
}

const BubbleContent = ({date, values, toggleEntryModal, jsx, total, expanded}) => {
    let display = expanded ? "block" : "none";
    return(
        <div className={jsx ? "bubble_content_wrapper" : "bubble_content_wrapper_empty"}>
            
            {total}
            <div className="expanded_view" style={{display: display, width: "100%"}}>
                <Expanded date={date} toggleEntryModal={toggleEntryModal} entry={values} jsx={jsx} />
            </div>
        </div>  
    );
}

const Expanded = ({date, entry, toggleEntryModal, jsx}) => {
    const [notes, setNotes] = useState("");
    let daySpendTotal = EntryHelpers.getDaySpendTotal(entry);
    let noteInput = useRef(null);
    let db = new Database();

    const handleInput = () => {
        db.updateNotes(date, noteInput.current.value);
        setNotes(noteInput.current.value);
    }
    
    const returnSteps = (steps) => {
        if(steps === undefined){
            return 0
        } else {
            return steps;
        }
    }

    useEffect(() => {
        setNotes(entry.notes);
    }, [entry.notes]);

    return(
        <div className="expanded_entries" style={{margin:'0px', padding:'0px'}}>
            
            <div className="expanded_underline" style={{backgroundColor: colors.black}}></div>
            {jsx}

            
                

                {/*<div className="expanded_info_heading">
                    <h2>Total Spend</h2>
                    <h2>£{daySpendTotal}</h2>
                </div>
                    <div className="expanded_info_heading">
                        <h2>Steps</h2>
                        <h2>{returnSteps(entry.steps)}</h2>
                    </div>*/}
                
                {/*<div className="expanded_info_target">
                    <img src={require('../../assets/icons/target_green.png')} alt="target" />
                </div>*/}
            
            <div className="expanded_notes">
                <textarea onChange={() => handleInput()} ref={noteInput} defaultValue={notes} placeholder="Enter notes here..."></textarea>
            </div>

            <div className="expanded_underline" style={{backgroundColor: colors.black}}></div>


            <div className="plus_icon">
                <PlusButton onClick={toggleEntryModal} type="plus" value="edit" />
            </div>
        </div>
    );
}


export default ValueBubble;