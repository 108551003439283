/**
 * EmptyBubble
 * 
 * The bubble inputs on the gambling timeline
 * 
 */

import React, { useState, useEffect } from 'react';
import DateHelpers from '../../classes/DateHelpers.js';
import Cookies from 'universal-cookie';
import colors from '../../Colors.js';
import PlusButton from '../plusbutton/PlusButton.js';

const dayArray = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];

const EmptyBubble = ({expanded, expandFunc,toggleEntryModal, date}) => {
    const [confirmed, setConfirmed] = useState(false);
    let dow = DateHelpers.dateToTimestamp(date);
    let isPast = verifyPastDate(dow);
    let display = isPast ? null : "none";
    const cookies = new Cookies();

    useEffect(() => {
        let response = cookies.get(date);
        if(response !== undefined){
            if(response === "true"){
                setConfirmed(true);
            }
        }
    }, [date, cookies])

    const updateConfirmed = () => {
        setConfirmed(true);
        cookies.set(date, true, {path: '/'});
    }

    return(
        <div>
        <div style={{marginBottom: "5px", color: colors.black, display: display}} className="timeline_row">
            <h4 style={{color: colors.black, flexBasis: "100%", margin: 0,marginLeft: "60px", padding: 0, fontSize: 20 }}>{date}</h4>
        </div>
        <div style={{marginBottom: "40px", color: colors.black, display: display}} className="timeline_row">
            <div className="entry_dot" style={{backgroundColor: colors.grey}}><h1 style={{ fontSize: 18, marginLeft: 0 }}>{returnDay(date)}</h1></div>
            
            <div className='entry_bubble_empty_collapsed'>
                <PlusButton onClick={toggleEntryModal} type="plus" />
            </div>
            
        </div>
        </div>
    );
}
/* 
            <div onClick={(event) => expandFunc(event)} className={!expanded ? "entry_bubble_empty_collapsed" : "entry_bubble_empty_expanded"} style={{backgroundColor: colors.grey}}>
                <div className={!expanded ? "empty_target_img_closed" : "empty_target_image_open"}>
                    {confirmed ? <img src={require('../../assets/icons/target_green.png')} alt="Target" /> : <div className="empty_plus_wrapper"><h1 style={{ fontSize: 18, marginLeft: 0 }}>Add data</h1></div> }
                </div>
                {confirmed ? 
                    <div className={!expanded ? "plus_icon_hide" : "plus_icon_shown"}>
                        <PlusButton onClick={toggleEntryModal} type="plus" />
                    </div>
                : 
                    <div className={!expanded ? "unconfirmed_hide" : "unconfirmed_show"}>
                        
                        <div style={{cursor: 'pointer'}} className="unconfirmed_row" onClick={() => updateConfirmed()}>
                            <h1 style={{color: colors.yellow}}>No Gambling today!</h1>
                        </div>
                        
                        <div className="unconfirmed_row_plus">
                            <PlusButton onClick={toggleEntryModal} type="plus" />
                        </div>
                    </div>
                }
            </div> */

const returnDay = (date) => {
    let dateTs = DateHelpers.dateToTimestamp(date);
    let todayTs = new Date();
    let givenDate = `${dateTs.getDate()}/${dateTs.getMonth()}/${dateTs.getYear()}`;
    let today = `${todayTs.getDate()}/${todayTs.getMonth()}/${todayTs.getYear()}`

    if(givenDate === today){
        return "Today";
    } else {
        return dayArray[dateTs.getDay()];
    }
}

const verifyPastDate = (date) => {
    let today = new Date();

    if(date > today){
        return false;
    } else {
        return true;
    }
}

export default EmptyBubble;