import React, { useState } from 'react';
import Input from '../../input/Input.js';
import Minheader from '../../minheader/Minheader.js';
import Background from '../../../assets/backgrounds/fifa.jpg';
import Button from '../../button/Button.js';
import Authentication from '../../../classes/Authentication.js';
import Footer from '../../footer/Footer.js';


const ForgotPassword = () => {
    const [email, setEmail] = useState("");

    const sendEmail = () => {
        let auth = new Authentication();
        auth.sendResetEmail(email);
    }

    return(
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`}}>
            <div className="content_wrapper login" id="signup">
                <Minheader heading="" />
                <Input onChange={setEmail} label="Email" placeholder="email" icon="profile" />
                <div style={{marginTop: "30px", cursor: "pointer"}}>
                    <Button onClick={sendEmail} label="Send password" />
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default ForgotPassword;