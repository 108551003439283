import React, { Component } from 'react';
import EntryModal from '../entrymodal/EntryModal.js';
import EntryHelpers from '../../classes/EntryHelpers.js';
import DateHelpers from '../../classes/DateHelpers.js';
import ValueBubble from '../valuebubble/ValueBubble.js';
import EmptyBubble from '../emptybubble/EmptyBubble.js';
import StepBubble from '../stepbubble/StepBubble.js';
import './TimelineEntry.css';

export default class TimelineEntry extends Component {
    constructor(){
        super();
        this.state = {
            values: [],
            totalRow: <div></div>,
            jsxRows: [],
            expanded: false,
            targets: {},
            empty: true,
            entryModal: false,
        }
    }

    componentDidMount(){
        let rows = EntryHelpers.getRowsFromEntries(this.props.values)
        if(rows === 0){
            this.setState({empty: true});
        } else {
            this.setState({values: this.props.values, targets: this.props.targets, empty: false,}, () => {
                this.getBubbleRows(this.state.values);
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.targets !== this.props.targets){
            let targets = {spend: this.props.targets.spend, time: this.props.targets.time};
            this.setState({
                targets: targets
            })
        }
    }

    getBubbleRows(values){
        let jsxRows = [];
        let rows = EntryHelpers.getRowsFromEntries(values);
        let entry = EntryHelpers.formatDailyEntries(values);

        if(entry === undefined | entry.length === 0 | values.nodata === true){
            jsxRows = false;
            this.setState({empty: true})
            return;
        } else if(entry.length === 1 && entry[0].category === "steps"){
            jsxRows = false;
            this.setState({empty: true})
            return;
        } else {
            let nodata = true;
            let spend = 0;
            let time = 0;
            
            for(var i = 0; i < rows; i++){
                if(entry[i].category !== "notes" && entry[i].category !== "steps" && entry[i].category !== "nodata"){
                    if ((entry[i].spend !== 0) | (entry[i].time !== 0)) {
                        jsxRows.push(<BubbleContentRow key={i} entry={entry[i]} />);
                        nodata = false;
                        spend += Number(entry[i].spend);
                        time += Number(entry[i].time);
                    }   
                }
            }
            if (nodata) {
                this.setState({totalRow: <h4 key={1} style={{fontSize: "1.5em"}}>No Gambling</h4>});
            } else {
                // There's only 24 hours in a day
                time = (time > 1440) ? 1440 : time;

                let total = {"category": "total", "spend": spend, "time": time};
                this.setState({totalRow: <BubbleContentRow key={rows} entry={total} />});

            }
        }
        this.setState({jsxRows: jsxRows, empty: false})
        return;
    }

    expandBubble(event){
        let target = event.target.parentNode.className;
        if(target === "plus_icon" | target === "plusbutton_wrapper" | target === "plus_value" | target === "expanded_notes"){
            return;
        } else {
            this.setState({expanded: !this.state.expanded});
        }
    }

    /**
     * This is triggered by the 'add' button (launch entryModal)
     */
    toggleEntryModal(){
        this.props.updateTotals(this.props.week);
        this.setState({entryModal: !this.state.entryModal})
    }

    updateEntries(entries){
        this.setState({values: entries}, () => {
            this.getBubbleRows(this.state.values);
        });
    }

    render(){
        let expanded = this.state.expanded;
        let date = this.props.date;
        var currentBubble;
        if(this.props.steps){
            currentBubble = <StepBubble targets={this.state.targets} key={(Math.random() * 23)} week={this.props.week} date={this.props.date} values={this.state.values} />
        } else {
            let emptyBubble = <EmptyBubble key={1} expanded={expanded} toggleEntryModal={this.toggleEntryModal.bind(this)} expandFunc={this.expandBubble.bind(this)} date={date} />;
            let valueBubble = <ValueBubble targets={this.state.targets} key={2} values={this.state.values} toggleEntryModal={this.toggleEntryModal.bind(this)} expanded={expanded} expandFunc={this.expandBubble.bind(this)} date={date} total={this.state.totalRow} jsx={this.state.jsxRows}  />
            currentBubble = this.state.empty ? emptyBubble : valueBubble;
        }

        return [currentBubble, <EntryModal week={this.props.week} updateEntriesState={this.updateEntries.bind(this)} key={3} date={date} onClick={this.toggleEntryModal.bind(this)} visible={this.state.entryModal} values={this.props.values} />]
    }
}

const BubbleContentRow = ({entry}) => {
    return(
        <div className="bubble_content_column">
            <div className="bubble_content_row">
                <img src={EntryHelpers.returnImageIcon(entry)} alt="category" />
            </div>
            <div className="bubble_content_row">
                <h4>{entry.category}</h4>
            </div>
            <div className="bubble_content_row">
                <h4>£{entry.spend}</h4>
            </div>
            <div className="bubble_content_row">
                <h4>{DateHelpers.returnFormattedTime(entry.time)}</h4>
            </div>
        </div>
    );
}