/**
 * Plus Button
 * 
 * This will display an 'add' button for adding data
 * 
 */

import React from 'react';
import './PlusButton.css';

const PlusButton = (props) => {
    let style;

    let value = (props.value === undefined) ? "Add data" : props.value; 

    if(props.type === "plus") {
        style = {color: props.color};
    } else {
        style = {transform: "rotate(45deg)", color: props.color};
    }

    return(
        <div className="plusbutton_wrapper" style={{backgroundColor: "props.bgColor"}} onClick={() => props.onClick()}>
            <h3 style={style} className="plus_value">{value}</h3>
        </div>
    );
}


export default PlusButton;