import React from 'react';
import colors from '../../Colors.js';
import './Button.css';

const Button = (props) => {
    let buttonColor = (props.buttonColor === undefined) ? colors.yellow : props.buttonColor;

    return(
        <div style={{backgroundColor: buttonColor}} className="button_container" onClick={() => props.onClick()}>
            <h1 id="button_label" style={{color: colors.black}}>{props.label}</h1>
        </div>
    );
}

export default Button;