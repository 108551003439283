import React from 'react';
import QuestionSwitch from '../../questionswitch/QuestionSwitch.js';
import Button from '../../button/Button.js';
import './Pinlock.css';
import colors from '../../../Colors.js';

const Pinlock = () => {
    return(
        <div>
            <QuestionSwitch label="Pinlock Enabled" />
            <div className="pinlock_code_input">
                <h3 style={{color: colors.black}}>Pincode</h3>
                <div className="pinlock_input_wrapper">
                    <input style={{backgroundColor: colors.yellow, color: colors.black}} type="password" placeholder="****" />
                </div>
            </div>
            <div style={{marginTop: "40px"}}>
                <Button label="Update" />
            </div>
        </div>
    );
}

export default Pinlock;