/**
 * Entry modal
 * 
 * Modal for entering gambling and thinking data
 * 
 */

import React, { useState, useEffect } from 'react';
import Colors from '../../Colors.js';
import CancelButton from '../cancelbutton/CancelButton.js';
import Database from '../../classes/Database.js';
import Button from '../button/Button.js';
import './EntryModal.css';

const TitleRow = () => {
    return(
        <div>
            <h1 style={{color: Colors.black, textAlign: 'center', marginLeft: 0, paddingLeft: 0, paddingTop: 20}}>Add data</h1>
            <div className="entry_modal_row" style={{flexWrap: 'wrap'}}>
                <p>Add how much money you have spent on gambling today, and how much time you have spent thinking about gambling today</p>
            </div>
            {/* <div className="entry_modal_row" style={{flexWrap: 'wrap'}}>
                <div className="entry_modal_column"></div>
                <div className="entry_modal_column">
                    <h4 style={{color: Colors.black}}>Spend</h4>
                </div>
                <div className="entry_modal_column">
                    <h4 style={{color: Colors.black}}>Time</h4>
                </div>
            </div> */}
        </div>
    );
}

const EntryRow = ({label, updateSpend, updateTime, spend, time}) => {
    return(
        <div className="entry_modal_row">
            <div className="entry_modal_column">
                <h4 style={{color: Colors.black}}>{label}</h4>
            </div>
            <div className="entry_modal_column">
                <input onChange={(event) => updateSpend(event.target.value)} placeholder="£" style={{backgroundColor: Colors.black, color: Colors.yellow}} type="number" min="0" max="9999" className="entry_modal_input" value={spend} />
            </div>
            <select onChange={(event) => updateTime(event.target.value)} style={{width: '40%', textAlign: 'center', borderRadius: 40, marginRight: 20, height: 50, backgroundColor: Colors.black, color: Colors.yellow}} className="entry_modal_select" value={time}>
                {returnTimeEntries()}
            </select>
        </div>
    );
}

const humanReadableTime = (mins) => {
    let time;

    (mins < 120) 
    ? time = mins + " mins"
    : time = Math.floor(mins/60) + " hrs";
    
    if ((mins >= 120) && (mins % 60 != 0)) {
        time = time + " " + (mins % 60).toFixed(0) + " mins";
    }

    return time;
}

const returnTimeEntries = () => {
    let options = [];
    var time = 0;

    for(var i = 0; i < 52; i++){
        options.push(<option key={i} value={time}>{humanReadableTime(time)}</option>);

        (time < 60) 
        ? time += 10
        : time += 30;
    }
    options.push(<option key={53} value={time}>All day</option>);

    return options;
}

const EntryModal = ({updateEntriesState, week, values, date, visible, onClick}) => {
    let display = visible ? "block" : "none";
    const [sportsSpend, setSportsSpend] = useState(values.sports.spend);
    const [sportsTime, setSportsTime] = useState(values.sports.time);
    const [casinoSpend, setCasinoSpend] = useState(values.casino.spend);
    const [casinoTime, setCasinoTime] = useState(values.casino.time);
    const [otherSpend, setOtherSpend] = useState(values.other.spend);
    const [otherTime, setOtherTime] = useState(values.other.time);
   
    const updateEntries = () => {
        let db = new Database();
        let sports = {spend: parseInt(sportsSpend), time: parseInt(sportsTime)};
        let casino = {spend: parseInt(casinoSpend), time: parseInt(casinoTime)};
        let other = {spend: parseInt(otherSpend), time: parseInt(otherTime)};

        if(db.dataEntry(sports, casino, other, date)){
            updateEntriesState({casino: casino, other: other, sports: sports});
            onClick(week);
        }
    }

    const spendSanitiser = (spend) => {
        spend = (Number(spend));
        spend = (spend < 0) ? 0 : spend;
        spend = (spend > 9999) ? 9999 : spend;
        return spend;
    }

    const sportsSpendHandler = (spend) => {
        spend = spendSanitiser(spend);
        setSportsSpend(spend);
    }

    const casinoSpendHandler = (spend) => {
        spend = spendSanitiser(spend);
        setCasinoSpend(spend);
    }

    const otherSpendHandler = (spend) => {
        spend = spendSanitiser(spend);
        setOtherSpend(spend);
    }

    return(
        <div className="entry_modal" onClick={(event) => event.stopPropagation()} style={{position: 'fixed', overflow: 'scroll', height: '100%', bottom: 0, backgroundColor: Colors.white, display: display}}>
            <TitleRow />
            <EntryRow label="Sports" updateSpend={sportsSpendHandler} updateTime={setSportsTime} spend={sportsSpend} time={sportsTime} />
            <EntryRow label="Casino" updateSpend={casinoSpendHandler} updateTime={setCasinoTime} spend={casinoSpend} time={casinoTime} />
            <EntryRow label="Other " updateSpend={otherSpendHandler} updateTime={setOtherTime} spend={otherSpend} time={otherTime} />
            <div style={{marginTop: "50px"}}>
                <Button label="Update" onClick={updateEntries} />
            </div>
            <div className="cancelbutton_container">
                <CancelButton onClick={() => onClick()} color={"white"} bgColor={Colors.yellow} type="close" />
            </div>
        </div>
    );
}

export default EntryModal;