import firebase from 'firebase/app';
import Database from './Database.js';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';

class Authentication {
    constructor(){
        this.initializeFirebase();
    }

    // initialise the firebase object
    initializeFirebase(){
        if(!firebase.apps.length){
            firebase.initializeApp(firebaseConfig);
            firebase.analytics();
        }
    }

    // user has made a log in attemot
    async loginAttempt(email, password){
        if(email === "" | email === " " | email === undefined | email === null | password === "" | password === " " | password === undefined | password === null){
            alert("All Fields Required!");
            return;
        }
        var status = true;
        await firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
            //var errorCode = error.code;
            var errorMessage = error.message;

            alert("Error!: "+errorMessage);
            status = false;
        });
        return status;
    }

    // user has made a sign in attempt
    async signupAttempt(name, email, password, confirmpasswpord){
        let validation = [name, email, password, confirmpasswpord];
        //console.log("Beginning signup attempt");
        for(var i = 0; i < validation.length; i++){
            if(validation[i] === "" || validation[i] === " " || validation[i] === undefined || validation[i] === null){
                alert("All fields must be filled out!");
                return;
            }
        }

        let error = await firebase.auth().createUserWithEmailAndPassword(email, password).then(() => {
            return false;
        }).catch(function(error) {
            var errorMessage = error.message;
            alert(errorMessage);
            //console.log("Signup Error: "+error.message);
            return true;
        });

        if(error){
            return;
        }

        let {uid} = await firebase.auth().currentUser;
        //console.log("UID: "+uid);

        //console.log("adding to the databse");

        let dbClass = new Database();
        let db = dbClass.initialiseUserEntries(uid, name, email);
        
        if(db){
            //console.log("Entry Added");
            return true;
        } else {
            console.log("Database Entry Failed");
            return false
        }
    }

    
    // Check whether the user is currently signed in
    async checkAuthenticated(history, cb = (x) => console.log("check logged in")){
        let checked = false;
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                //console.log("Authentication Required");
                history.push("/login");
                //cb(false);
            } else {
                //console.log("User Authenticated");
                this.uid = user.uid;
                cb(true);
                checked = true;

            }


        });

        //console.log("HERES " + this.uid);

    }

    async resetPassword(password){
        firebase.auth().currentUser.updatePassword(password).then((response) => {
            alert("Success");
        }).catch((error) => {
            alert(error)
        })
    }

    async sendResetEmail(email){
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            alert("Email Sent!");
        }).catch((error) => {
            alert("There was an error sending the email, please try again later");
        })
    }

    // sign the user out
    async signOut(){
        let result = firebase.auth().signOut().then(() => {
            //console.log("signed out");
            return true;
        }).catch((error) => {
            //console.log("There was an error!");
            //console.log(error);
            return false;
        })
        return result;
    }
}


const firebaseConfig = {
    apiKey: "AIzaSyBMFmkDwTSMmsO9Rmeu_N1jDrFB5sQPkT8",
    authDomain: "ffab-17d8a.firebaseapp.com",
    databaseURL: "https://ffab-17d8a.firebaseio.com",
    projectId: "ffab-17d8a",
    storageBucket: "ffab-17d8a.appspot.com",
    messagingSenderId: "440780813477",
    appId: "1:440780813477:web:e5dafa2fec34cdfb4a494c",
    measurementId: "G-FC717JX6NS"
};

export default Authentication;