import React from 'react';
import { useParams } from 'react-router-dom';
import MinHeader from '../../minheader/Minheader.js';
import Notifications from '../notifications/Notification.js';
import UpdateDetails from '../updatedetails/UpdateDetails.js';
import ResetPassword from '../resetpassword/ResetPassword.js';
import Pinlock from '../pinlock/Pinlock.js';
import Background from '../../../assets/backgrounds/fifa.jpg';
import './Set.css';

const Set = () => {
    let { tab } = useParams(); 
    let heading = ""; //returnHeading(tab);

    return(
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`, backgroundSize: 'cover'}}>
            <div className="content_wrapper">
                <MinHeader heading={heading} />
                {chosenView(tab)}
            </div>
        </div>
    );
}

var chosenView = (tab) => {
    if(tab === "notifications"){
        return <Notifications />;
    } else if (tab === "updatedetails"){
        return <UpdateDetails />
    } else if (tab === "resetpassword"){
        return <ResetPassword />
    } else if (tab === "pinlock"){
        return <Pinlock />
    }
}

const returnHeading = (heading) => {
    if (heading === "notifications"){
        return "Notifications";
    } else if (heading === "updatedetails") {
        return "Update Details";
    } else if (heading === "resetpassword"){
        return "Reset Password";
    } else if (heading === "pinlock"){
        return "Pin Lock";
    } else {
        return "Error!";
    }
}

export default Set;