import React, { useState, useEffect } from 'react';
import Input from '../../input/Input.js'; 
import Button from '../../button/Button.js';
import { Link, useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import Background from '../../../assets/backgrounds/pexels-fabricio-trujillo-54567.jpg';
import Authentication from '../../../classes/Authentication.js';
import colors from '../../../Colors.js';
import './Login.css';
import Footer from '../../../components/footer/Footer.js';

const Login = (props) => {
    const [checked, setChecked] = useState(false);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    let history = useHistory();
    let auth = new Authentication();

    const performSignOut = async () => {
        let signedOut = auth.signOut();
    }

    useEffect(() => {
        performSignOut();
    }, [])

    const loginAttempt = async () => {
        var status = await auth.loginAttempt(username, password);
        if(status === true){
            history.push("/home");
        } else {
            return;
        }
    }
    
    return(
        <div className="background_wrapper" style={{backgroundImage: `url(${Background})`, backgroundSize: 'cover'}}>
            <div className="content_wrapper">
                
                <div className="logo" style={{marginTop: '20px'}}>
                    <img src={require("../../../assets/logos/rtg_black.svg")} alt="Logo Yellow" />
                </div>
                <div className="input_wrapper">
                    <Input labelColor={colors.black} onChange={setUsername} label="Username" placeholder="email" icon="profile" />
                    <Input labelColor={colors.black} password={true} onChange={setPassword} label="Password" placeholder="password" icon="password"/>
                    <div className="login_options_container">
                        <Link to="/forgotpassword">
                            <div className="forgot_password">
                                <h3 style={{color: colors.black, fontSize: 16}}>Forgot Password?</h3>
                            </div>
                        </Link>
                    </div>
                    <div className="button_wrapper login" style={{marginTop: "20px"}}>
                        <Button label="Login" onClick={loginAttempt}  />
                    </div>
                    
                    <div className="button_wrapper">
                        <Link to="/signup">
                            <h3 style={{color: colors.black}}>Sign Up</h3>
                        </Link>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Login